<template>
  <div class="wameed-dashboard-page-content mb-5">
    <section class="wameed-dashboard-page-content_body">
      <b-row class="no-gutters px-2">
        <b-col lg="3" md="4" class="px-0 d-none">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="companyDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <company-icon></company-icon>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.company_data') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="advance"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <settings-settings-icon/>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.advance.title') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0 ">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="currencies"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <settings-currencies-icon/>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.currencies') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0 ">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="activeProviders"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <company-icon></company-icon>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.active_providers') }}
            </h4>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="no-gutters px-2">
        <b-col lg="3" md="4" class="px-0">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="destinations"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <settings-destinations-icon/>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.destinations') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="categories"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <settings-categories-icon/>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.categories') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0 ">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="interests"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <settings-interests-icon/>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.interests') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0 ">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="ads"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <settings-ads-icon/>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.ads') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0 ">
          <b-card
              no-body
              class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
              @click="newsletters"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <settings-ads-icon/>
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.newsletter') }}
            </h4>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    companyDetails() {
      this.$router.push({
        name: 'company-information',
        params: {lang: this.$i18n.locale},
      });
    },
    activeProviders() {
      this.$router.push({
        name: 'active-providers',
        params: {lang: this.$i18n.locale},
      });
    },
    currencies() {
      this.$router.push({name: 'currencies', params: {lang: this.$i18n.locale}});
    },
    categories() {
      this.$router.push({name: 'categories', params: {lang: this.$i18n.locale}});
    },
    interests() {
      this.$router.push({name: 'interests', params: {lang: this.$i18n.locale}});
    },
    destinations() {
      this.$router.push({name: 'destinations', params: {lang: this.$i18n.locale}});
    },
    advance() {
      this.$router.push({name: 'advance', params: {lang: this.$i18n.locale}});
    },
    ads() {
      this.$router.push({name: 'ads', params: {lang: this.$i18n.locale}});
    },
    newsletters() {
      this.$router.push({name: 'newsletters', params: {lang: this.$i18n.locale}});
    },
    jobs() {
      this.$router.push({name: 'jobs', params: {lang: this.$i18n.locale}});
    },

  },
};
</script>
 
